import { connect } from 'react-redux';
import LandingInfoPresentation from './LandingInfoPresentation';
import actions from './actions';
import { change } from "redux-form";
import { formValueSelector } from 'redux-form';


function mapStateToProps(state) {
    const { home } = state;
    return {
        home,
        
    }
}

function mapDispatchToProps(dispatch) {
    return {
        SendUserInterest: (userInterest) => { dispatch(actions.SendUserInterest(userInterest)) },
    }
}

const LandingInfo = connect(mapStateToProps, mapDispatchToProps)(LandingInfoPresentation);

export default LandingInfo;