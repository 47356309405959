const homeConstants = {

    HOME_SENDEMAIL_SUCCESS: 'HOME_SENDEMAIL_SUCCESS',
    HOME_SENDEMAIL_FAIL: 'HOME_SENDEMAIL_FAIL',

    HOME_USERINTEREST_SUCCESS: 'HOME_USERINTEREST_SUCCESS',
    HOME_USERINTEREST_FAIL: 'HOME_USERINTEREST_FAIL',

}

export default homeConstants;