import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
//import homepage from './homepage.css'
//import ContactUsForm from './ContactUsForm'
//import actions from './actions'
import LandingInfoForm from './LandingInfoForm'
import { hot } from "react-hot-loader";
import CallIcon from '../../image/ContactUs/call-svgrepo-com.svg';
import AddressIcon from '../../image/ContactUs/location-1-svgrepo-com.svg';
import ContactUsCharact from '../../image/ContactUs/contactUs-character.png';

import InsubeeVariationA from '../../image/Home/InsubeeVariationA.png'
import InsubeeVariationAMobile from '../../image/Home/InsubeeVariationAMobile.png'
import InsubeeVariationB from '../../image/Home/InsubeeVariationBanner12.jpg'
import InsubeeVariationBMobile from '../../image/Home/InsubeeVariationBannerMobile12.jpg'
import Browser from '../_helpers/browser';


class LandingInfoPresentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CaptchaValue: false,
        };

        this.SendUserInterest = this.SendUserInterest.bind(this);

    }

    SendUserInterest(form) {

        const { IsLandingB } = this.props;

        var userInterest = {

            Name: form.name,
            SurName: form.surname,
            ContactNumber: form.ContactNumber,
            Email: form.email,
            Interest: form.interest,
            isLandingB: IsLandingB,
        }

        this.props.SendUserInterest(userInterest);
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {

        const { home, isPrivacyNotice, IsLandingB } = this.props;

        let DisableButton = true;

        if (isPrivacyNotice) {

            if (isPrivacyNotice == false || this.state.CaptchaValue == false) {

                DisableButton = true;
            }

            else {

                DisableButton = false;
            }
        }

        return (

            <>
                {(() => {
                    if (IsLandingB == true) {
                        if (Browser.isMobile()) {
                            return (
                                <>
                                    <div className={IsLandingB == true ? "HomeVariation-infoB" : "HomeVariation-info"} style={{ position: "relative" }}>

                                        <img src={Browser.isMobile() ? InsubeeVariationBMobile : InsubeeVariationB} className="" style={{ width: "100%", height: "100%", position: "absolute", objectFit: "cover" }} />

                                        <div className="contactUs-presentation" style={{ height: "591px" }}>
                                            <div className="contactUs-container  LandingB-mobile">
                                                <div className="contactUs-form-container">
                                                    {
                                                        IsLandingB == true ?
                                                            <>
                                                                <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Tomorrow?<br /></b></h1>
                                                            </>
                                                            :
                                                            <>
                                                                <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Dreams?<br /></b></h1>
                                                            </>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{ padding: "2rem" }}>

                                        <h4 style={{ maxWidth: "636px" }}>Contact us today and experience the Insubee difference!<br />
                                            Let's embark on this journey of protection together.
                                        </h4>

                                        <LandingInfoForm
                                            onSubmit={this.SendUserInterest}
                                            DisableButton={DisableButton}
                                            onChangeReCAPTCHA={this.onChangeReCAPTCHA}
                                            LandingB={IsLandingB}
                                        />
                                    </div>
                                </>
                            )
                        }

                        else {
                            return (
                                <div className={IsLandingB == true ? "HomeVariation-infoB" : "HomeVariation-info"} style={{ position: "relative" }}>

                                    <img src={Browser.isMobile() ? InsubeeVariationBMobile : InsubeeVariationB} className="" style={{ width: "100%", height: "100%", position: "absolute", objectFit: "cover" }} />

                                    <div className="contactUs-presentation">
                                        <div className="contactUs-container">
                                            <div className="contactUs-form-container">
                                                {
                                                    IsLandingB == true ?
                                                        <>
                                                            <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Tomorrow?<br /></b></h1>
                                                        </>
                                                        :
                                                        <>
                                                            <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Dreams?<br /></b></h1>
                                                        </>
                                                }

                                                <br />
                                                <h4 style={{ maxWidth: "636px" }}>Contact us today and experience the Insubee difference!<br />
                                                    Let's embark on this journey of protection together.
                                                </h4>

                                                <div>
                                                    <LandingInfoForm
                                                        onSubmit={this.SendUserInterest}
                                                        DisableButton={DisableButton}
                                                        onChangeReCAPTCHA={this.onChangeReCAPTCHA}
                                                        LandingB={IsLandingB}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            )
                        }
                    }

                    else {

                        return (
                            <div className={IsLandingB == true ? "HomeVariation-infoB" : "HomeVariation-info"} style={{ position: "relative" }}>

                                <img src={Browser.isMobile() ? InsubeeVariationAMobile : InsubeeVariationA} className="" style={{ width: "100%", height: "100%", position: "absolute", objectFit: "cover" }} />

                                <div className="contactUs-presentation">
                                    <div className="contactUs-container">
                                        <div className="contactUs-form-container">
                                            {
                                                IsLandingB == true ?
                                                    <>
                                                        <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Tomorrow?<br /></b></h1>
                                                    </>
                                                    :
                                                    <>
                                                        <h1 style={{ fontSize: "40px" }}><b>Ready to Safeguard Your Dreams?<br /></b></h1>
                                                    </>
                                            }

                                            <br />
                                            <h4 style={{ maxWidth: "636px" }}>Contact us today and experience the Insubee difference!<br />
                                                Let's embark on this journey of protection together.
                                            </h4>

                                            <div>
                                                <LandingInfoForm
                                                    onSubmit={this.SendUserInterest}
                                                    DisableButton={DisableButton}
                                                    onChangeReCAPTCHA={this.onChangeReCAPTCHA}
                                                    LandingB={IsLandingB}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )

                    }
                })()}
            </>

        );
    }
}


export default hot(module)(LandingInfoPresentation);
