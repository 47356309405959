import { handleResponse } from "../_helpers/handleResponse";
import { authHeader } from '../_helpers/authHeader';
import constants from '../_constants/constants';

const homeServices = {

    SendEmail,
    SendUserInterest,

}

function SendEmail(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiSendEmail, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiSendEmail)
    ).then(data => {
        return data;
    });
}

function SendUserInterest(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiSendUserInterest, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiSendUserInterest)
    ).then(data => {
        return data;
    });
}


export default homeServices;