import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Button } from 'reactstrap';
import { Field, reduxForm, FormSection, FieldArray } from 'redux-form'
import ValidateField from '../GlobalComponents/FormElements/ValidateField';
import ValidateFieldTextArea from '../GlobalComponents/FormElements/ValidateFieldTextArea';
import CheckboxField from "../GlobalComponents/FormElements/CheckboxField";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { required, email, onlyNumbers, onlyNumbersWithMaxLength, maxLength } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import { hot } from "react-hot-loader";
import Browser from '../_helpers/browser';


const PhoneNumberNormalize = onlyNumbersWithMaxLength(11);


class LandingInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { handleSubmit, change, isLoading, DisableButton, onChangeReCAPTCHA, LandingB } = this.props;



        return (

            <form onSubmit={handleSubmit}>
                <Container className="formBackground" style={{ padding: "0" }}>
                    <Card style={{ backgroundColor: "transparent", border: "none" }}>
                        <CardBody style={{ padding: "1.25rem 0" }}>

                            <Row>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="name">Name<span className="required">*</span></label>
                                    <Field className="form-control" name="name" label="Name" required="true" component={ValidateField} type="text" />
                                </Col>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="surname">Surname</label>
                                    <Field className="form-control" name="surname" label="Surname" component={ValidateField} type="text" />
                                </Col>
                            </Row>

                            <br />

                            <Row>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="Email">Email<span className="required">*</span></label>
                                    <Field className="form-control" name="email" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                </Col>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="ContactNumber">Contact Number<span className="required">*</span></label>
                                    <Field className="form-control" name="ContactNumber" label="ContactNumber" maxLength={11} component={ValidateField} validate={[required]} type="phone" normalize={PhoneNumberNormalize, onlyNumbers} required="true" />
                                </Col>
                            </Row>


                            <br />

                            <Row>
                                <Col xs="12" sm="12" lg="12">
                                    <label htmlFor="Enquiry">Interested in coverage for<span className="required">*</span></label>
                                    <Field
                                        name="interest"
                                        component={DropdownSelectField}
                                        label="interest"
                                        filter={false}
                                        validate={[required]}
                                        type="text"
                                        data={
                                            [
                                                "Home Based Office - Online Sellers",
                                                "Office & Service",
                                                "Retail",
                                                "F&B",
                                                "Budget Hotel",
                                                "Beauty Wellness"
                                            ]
                                        }
                                        placeholder="Please select"
                                    />
                                </Col>

                            </Row>



                        </CardBody>
                    </Card>

                    <br />
                    <Row>

                        {LandingB == true ?
                            <div style={{ margin: "auto", width: "300px" }}>
                                <Button type="submit" className="btnInsubeeR-variation" disabled="">
                                    Submit
                                </Button>
                            </div>
                            :
                            <>
                                <div style={{ margin: "auto", width: "300px" }}>
                                    <Button type="submit" className="btnInsubeeR" disabled="">
                                        Submit
                                    </Button>
                                </div>
                            </>
                        }


                    </Row>
                </Container>
            </form>

        )
    }
}


LandingInfoForm = reduxForm({

    form: 'LandingInfoForm',
    destroyOnUnmount: true,

})(LandingInfoForm)

export default hot(module)(LandingInfoForm);
