import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
//import homepage from './homepage.css'
//import ContactUsForm from './ContactUsForm'
//import actions from './actions'
import ContactUsForm from './ContactUsForm'
import { hot } from "react-hot-loader";
import CallIcon from '../../image/ContactUs/call-svgrepo-com.svg';
import AddressIcon from '../../image/ContactUs/location-1-svgrepo-com.svg';
import ContactUsCharact from '../../image/ContactUs/contactUs-character.png';


class ContactUsPresentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CaptchaValue: false,
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.onChangeReCAPTCHA = this.onChangeReCAPTCHA.bind(this);
    }

    sendEmail(emailDetails) {

        var Email = {

            Name: emailDetails.name,
            ContactNumber: emailDetails.ContactNumber,
            Email: emailDetails.email,
            Message: emailDetails.Enquiry,
        }

        this.props.SendEmail(Email);
    }

    onChangeReCAPTCHA(value) {

        if (value != null) {

            this.setState({
                CaptchaValue: true
            });
        }

        else {
            this.setState({
                CaptchaValue: false
            });
        }
        //console.log("Captcha value:", value);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {

        const { home, isPrivacyNotice } = this.props;

        let DisableButton = true;

        if (isPrivacyNotice) {

            if (isPrivacyNotice == false || this.state.CaptchaValue == false) {

                DisableButton = true;
            }

            else {

                DisableButton = false;
            }
        }

        return (

            <div className="my-5 pt-sm-5">


                <div className="contactUs-presentation">
                    <div className="contactUs-container">
                        <div className="contactUs-form-container">
                            <h1 style={{ fontSize: "48px" }}><b>Got a question?<br /><span style={{ backgroundColor: "#FFFF00", fontSize: "48px" }}>No problem.</span></b></h1>
                            <br />
                            <p style={{ maxWidth: "367px" }}>Fill in your details and drop us a note.We&apos;ll get back to you as soon as we can!</p>
                            <br />
                            <div className="contactUs-container--1">
                                <img src={CallIcon} />
                                <div className="contactUs-text">
                                    <p>03-7787 2222</p>
                                </div>
                            </div>
                            <div className="contactUs-container--1">
                                <img src={AddressIcon} />
                                <div className="contactUs-text">
                                    <p>
                                        No. 19, Jalan Tandang,
                                        <br />
                                        46050 Petaling Jaya,
                                        <br />
                                        Selangor Darul Ehsan Malaysia.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <ContactUsForm
                                    onSubmit={this.sendEmail}
                                    DisableButton={DisableButton}
                                    onChangeReCAPTCHA={this.onChangeReCAPTCHA}
                                />
                            </div>

                        </div>


                        <div className="contactUs-image-container">
                            <img src={ContactUsCharact} />
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}


export default hot(module)(ContactUsPresentation);
