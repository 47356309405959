import homeConstants from './constants';

const initialState = {

};

//export default function home(state = {}, action) {
//    switch (action.type) {

//        default:
//            return state;
//    }
//}

export const HomeStore = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        //SendEmail
        case homeConstants.HOME_SENDEMAIL_SUCCESS:
            return {
                ...state,
                EmailResult: action.data,
                isLoading: false,
                showSuccess: true
            }
        case homeConstants.HOME_SENDEMAIL_FAIL:
            return {
                isLoading: false,
                showSuccess: false,
            }

        default:
            return state;
    }
}